import React, { useCallback, useEffect, useRef, useState } from 'react';
import { b64ToObject, objectToB64 } from "./common/util";
import axios from 'axios';
import { catchError, from, map, of, switchMap, tap, zip } from "rxjs";
import jwtEncode from 'jwt-encode';

const SESSION_CACHE_TIMEOUT = 5 * 60 * 1000; // Five minutes

const DEFAULT_TARGET_ELEMENT_ID = "onlive-widget";

const shoppableTokenData = (shoppableId, extra) => ({
    ...extra,
    shoppableId,
    type: 'shoppable',
    roles: [
        'viewer',
        'shoppable'
    ]
});

const liveEventTokenData = (eventId, extra) => ({
    ...extra,
    eventId,
    roles: ['viewer']
});

export const WidgetLoader = () => {

    const [params, setParams] = useState({});
    const [tokenParam, setTokenParam] = useState('');
    const [token, setToken] = useState('');
    const [organizationId, setOrganizationId] = useState('');
    const [onliveWidget12mV3, setOnliveWidget12mV3] = useState(null);
    const tokenData = useRef(null);

    useEffect(() => {
        setTokenParam(window.location.pathname.toString().split('/').pop());
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        const embeddedOptions = {};
        Object.keys(params).forEach(key => {
            let target = params;
            let opt = key;
            if (key.startsWith('embedded.')) {
                target = embeddedOptions;
                opt = key.split('.')[1];
            }
            try {
                target[opt] = JSON.parse(params[key]);
            } catch (_) {
                target[opt] = params[key];
            }
        });
        if (Object.keys(embeddedOptions).length) {
            params.embedded = embeddedOptions;
        }
        setParams(params);
    }, []);

    const isRecorder = useCallback(() => {
        return params.embedded?.recorder || tokenData.current?.roles?.find(
            role => role?.toString()?.includes('recorder')
        )
    }, [params]);

    useEffect(() => {
        if (tokenParam) {
            try {
                const data = tokenData.current = b64ToObject(tokenParam);
                if (!data.roles) {
                    data.roles = ['viewer'];
                }
                if (data.shoppableId || data.videoId) {
                    if (!data.roles.includes('shoppable')) {
                        data.roles.push('shoppable');
                    }
                    if (!data.type) {
                        data.type = 'shoppable';
                    }
                }
                setToken(objectToB64(data));
            } catch (_e) {
                tokenData.current = {};
            }
            const { eventId, shoppableId, organizationId, videoId, deleted } = tokenData.current;
            let eventData;
            if (eventId || shoppableId || (videoId && organizationId)) {
                if (organizationId) {
                    eventData = of ({ data: { organizationId } });
                } else if (shoppableId) {
                    eventData = from(axios.get(
                        `${process.env.REACT_APP_SHOPPABLE_API_URL}/${shoppableId}`, {
                            params: { deleted }
                        }
                    ));
                } else {
                    eventData = from(axios.get(
                        `${process.env.REACT_APP_SOCKET_API_URL}/events/${eventId}`
                    ));
                }
            } else {
                const catchToken = (id, tokenDataBuilder) => setToken(jwtEncode(
                    tokenData.current = tokenDataBuilder(id, tokenData.current), ''
                ).split('.')[1]);

                if (organizationId) {
                    eventData = tokenData.current.skipEventLoad
                        ? of({ data: { organizationId } })
                        : from(axios.get(
                            `${process.env.REACT_APP_SOCKET_API_URL}/events/highlight/${organizationId}`
                        )).pipe(
                            catchError(() => of({})),
                            switchMap(res => {
                                if (res.data) {
                                    catchToken(res.data.id, liveEventTokenData);
                                    return of(res);
                                }

                                return from(axios.get(
                                    `${process.env.REACT_APP_SHOPPABLE_API_URL}/organization/${organizationId}`, {
                                        params: { limit: 1 },
                                    }
                                )).pipe(
                                    catchError(() => of({ data: { items: [] } })),
                                    switchMap(({ data }) => {
                                        if (data.items.length) {
                                            catchToken(data.items[0]._id, shoppableTokenData);
                                            return of({ data: { organizationId } });
                                        }

                                        return of({});
                                    })
                                );
                            })
                        );
                } else {
                    eventData = from(axios.get(
                        `${process.env.REACT_APP_SHOPPABLE_API_URL}/${tokenParam}`
                    )).pipe(
                        catchError(() => of({})),
                        switchMap(res => {
                            if (res.data) {
                                catchToken(tokenParam, shoppableTokenData);
                                return of(res);
                            }

                            return from(axios.get(
                                `${process.env.REACT_APP_SOCKET_API_URL}/events/${tokenParam}`
                            )).pipe(
                                catchError(() => of({})),
                                tap(res => {
                                    if (res.data) {
                                        catchToken(tokenParam, liveEventTokenData);
                                    }
                                })
                            );
                        })
                    );
                }
            }
            if (eventData) {
                const subscription = eventData.subscribe(({ data }) => {
                    if (data?.organizationId) {
                        setOrganizationId(data.organizationId);
                    } else {
                        window.parent?.postMessage(
                            {
                                __ONLIVE_MSG_KEY: 'EVENT_FAILED',
                                eventId: eventId || shoppableId || videoId || tokenParam
                            },
                            '*',
                        );
                    }
                });
                return () => subscription.unsubscribe();
            }
        }
    }, [tokenParam]);

    useEffect(() => {
        if (organizationId) {
            const storageKey = `onliveWidget12mV3-${organizationId}` + (params.i ? `-${params.i}` : '');
            let onliveWidget12mV3Cache;
            try {
                onliveWidget12mV3Cache = JSON.parse(sessionStorage.getItem(storageKey));
            } catch (_) {
                // Nothing to do here
            }
            if (onliveWidget12mV3Cache?.timestamp + SESSION_CACHE_TIMEOUT > Date.now()) {
                if (isRecorder()) {
                    console.debug('recorder-mode-loaded');
                    setTimeout(
                        () => setOnliveWidget12mV3(onliveWidget12mV3Cache.data),
                        1000 * (+params.delayLoad || 0)
                    );
                } else {
                    setOnliveWidget12mV3(onliveWidget12mV3Cache.data);
                }
            } else {
                try {
                    sessionStorage.removeItem(storageKey);
                } catch (_) {
                    // Nothing to do here
                }
                const subscription = from(axios.get(
                    `${process.env.REACT_APP_API_GATEWAY_URL}/installations/${organizationId}`
                )).pipe(
                    switchMap(({data}) => {
                        if (Array.isArray(data)) {
                            const host = new URL(window.location.href).host
                            let onliveI;
                            let onlivePlayer;
                            let onliveWidget12mV3;
                            for (let i = 0; !onlivePlayer && i < data.length; i++) {
                                const { serviceName, name, src, options } = data[i];
                                if (serviceName === 'onliveWidget12mV3' && (src || options?.src)) {
                                    if (name && params.i && name === params.i) {
                                        onliveI = data[i];
                                    } else if (name && name.trim().toLowerCase() === host) {
                                        onlivePlayer = data[i];
                                    } else if (!onliveWidget12mV3) {
                                        onliveWidget12mV3 = data[i];
                                    }
                                }
                            }
                            if (onliveI) {
                                console.debug('Using custom installation', params.i);
                                return of(onliveI);
                            }
                            if (params.i) {
                                console.warn('Custom installation', params.i, 'not found');
                            }
                            if (onlivePlayer) {
                                return of(onlivePlayer);
                            }
                            if (onliveWidget12mV3) {
                                return of(onliveWidget12mV3);
                            }
                        }
                        return from(axios.get(
                            `${process.env.REACT_APP_SOCKET_API_URL}/applications/name/onliveWidget12mV3`
                        )).pipe(
                            map(({ data: { src } }) => ({ src }))
                        )
                    })
                ).subscribe(data => {
                    try {
                        sessionStorage.setItem(storageKey, JSON.stringify({
                            timestamp: Date.now(), data
                        }));
                    } catch (_) {
                        // Nothing to do here
                    }
                    if (isRecorder()) {
                        console.debug('recorder-mode-loaded');
                        setTimeout(() => setOnliveWidget12mV3(data), 1000 * (+params.delayLoad || 0));
                    } else {
                        setOnliveWidget12mV3(data);
                    }
                });

                return () => subscription.unsubscribe();
            }
        }
    }, [organizationId]);

    useEffect(() => {
        if (token && organizationId && onliveWidget12mV3) {
            const widgetUrl = onliveWidget12mV3.options?.src || onliveWidget12mV3.src;
            window.directLoad = true;
            const isShoppable = !!(tokenData.current.shoppableId || (tokenData.current.videoId && tokenData.current.organizationId));
            let theToken = token;
            if (typeof params.public === 'boolean') {
                theToken = objectToB64({ ...tokenData.current, public: params.public });
            }
            (function (w, d, s, o, f, js, fjs) {
                w.onliveWidgets = w.onliveWidgets || {};
                w['Onlive-Widget'] = w.onliveWidgets['Onlive-Widget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                // eslint-disable-next-line no-unused-expressions
                js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
            }(window, document, 'script', 'onliveW', widgetUrl));
            window.onliveW('init', {
                ...onliveWidget12mV3.options,
                organizationId,
                token: theToken,
                targetElementId: DEFAULT_TARGET_ELEMENT_ID,
                embedded: params.embedded ? {
                    ...params.embedded,
                    trailer: params.trailer && !isShoppable,
                } : false,
                trailerMode: params.trailer && isShoppable,
                closeOnExit: params.closeOnExit,
                hideExit: params.hideExit,
                embeddedExit: params.embeddedExit,
                liveAlways: params.liveAlways,
                autoplay: Boolean(params.autoplay || tokenData.current.eventId),
                startTime: Math.max(0, parseInt(params.startTime)),
                defaultLanguage: params.lang,
                topPlayer: params.topPlayer,
                minimizable: params.minimizable,
                inlineRender: true,
            });
        }
    }, [token, organizationId, onliveWidget12mV3]);

    return (
        <div id={DEFAULT_TARGET_ELEMENT_ID}
             style={{
                 height: '100%',
                 background: isRecorder() ? '#000000' : undefined
        }}/>
    );
}